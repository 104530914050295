import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class OemRoleGuard {
  isOem = false;
  constructor(public router: Router) {}
  canActivate(): boolean | UrlTree {
    if (this.isOem) {
      return true;
    }
    return this.router.parseUrl('/denied');
  }
}
